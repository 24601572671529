var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    { staticClass: "dn-browse" },
    [
      _vm.browse.loading
        ? _c("v-progress-linear", { attrs: { indeterminate: "" } })
        : _vm._e(),
      _c("div", _vm._b({}, "div", _vm.headerAttrs, false), [
        _vm.filtered
          ? _c("div", _vm._b({}, "div", _vm.headerBgAttrs, false))
          : _vm._e(),
        _c(
          "div",
          { staticClass: "dn-container" },
          [
            _c("back-button", { on: { back: _vm.handleBack } }),
            _c("h1", { staticClass: "dn-browse__title" }, [_vm._v("Browse")]),
            _c("search-bar", {
              ref: "searchBar",
              on: { input: _vm.handleSearch, focus: _vm.handleSearchFocus },
              model: {
                value: _vm.search,
                callback: function ($$v) {
                  _vm.search = $$v
                },
                expression: "search",
              },
            }),
            !_vm.filtered
              ? _c("tabs", {
                  attrs: { items: _vm.headers, active: _vm.active },
                  on: { tab: _vm.handleSetTab },
                })
              : _c("h3", { staticClass: "dn-browse__filtered-name" }, [
                  _vm._v(" " + _vm._s(_vm.filteredItem.name) + " "),
                ]),
          ],
          1
        ),
      ]),
      _c("div", { staticClass: "dn-container" }, [
        _c(
          "div",
          { staticClass: "dn-browse__content" },
          [
            _vm.loading
              ? _c("v-progress-linear", { attrs: { indeterminate: "" } })
              : _vm.gridItems.length && !_vm.filtered
              ? _c(
                  "grid",
                  [
                    _vm._l(_vm.gridItems, function (item, i) {
                      return [
                        _c(
                          "v-col",
                          {
                            key: i,
                            attrs: {
                              cols: _vm.activeTab.name !== "Artists" ? 12 : 6,
                              sm: 6,
                              md: 4,
                              lg: 3,
                              xl: 2,
                            },
                          },
                          [
                            _vm.activeTab.name === "Packs"
                              ? [_c("pack-tile", { attrs: { pack: item } })]
                              : _vm.activeTab.name === "Videos"
                              ? [_c("video-tile", { attrs: { video: item } })]
                              : _vm.activeTab.name === "Artists"
                              ? [
                                  _c("artist-tile", {
                                    attrs: { artist: item },
                                    on: {
                                      filterArtist: function ($event) {
                                        return _vm.filterBy("artist", item)
                                      },
                                    },
                                  }),
                                ]
                              : _vm._e(),
                          ],
                          2
                        ),
                      ]
                    }),
                  ],
                  2
                )
              : !_vm.gridItems.length && !_vm.filtered
              ? _c("p", { staticClass: "ml-10" }, [_vm._v(" No Results ")])
              : _vm.filtered
              ? [
                  _c(
                    "div",
                    { staticClass: "dn-browse__filtered" },
                    [
                      _vm.filteredItemLoops.length
                        ? _c("loop-table", {
                            attrs: { loops: _vm.filteredItemLoops },
                          })
                        : _vm._e(),
                      _vm.filteredItem.packs.length
                        ? _c("carousel", {
                            attrs: {
                              title: "Packs by " + _vm.filteredItem.name,
                              items: _vm.filteredItem.packs,
                              type: _vm.pack,
                              perView: 6,
                              overflow: true,
                              breakpoints: {
                                600: {
                                  perView: 2.1,
                                },
                                1000: {
                                  perView: 3,
                                },
                                1600: {
                                  perView: 4,
                                },
                              },
                            },
                          })
                        : _vm._e(),
                      _vm.filteredItem.videos.length
                        ? _c("carousel", {
                            attrs: {
                              title: "Videos with " + _vm.filteredItem.name,
                              items: _vm.filteredItem.videos,
                              perView: 4,
                              overflow: true,
                              breakpoints: {
                                600: {
                                  perView: 1.5,
                                },
                                1000: {
                                  perView: 2.5,
                                },
                                1600: {
                                  perView: 3,
                                },
                              },
                              type: "video",
                            },
                          })
                        : _vm._e(),
                    ],
                    1
                  ),
                ]
              : _vm._e(),
          ],
          2
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }