var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    {
      staticClass: "dn-artist-tile elevation-0 pa-2",
      on: {
        click: function ($event) {
          return _vm.$emit("filterArtist", _vm.artist)
        },
      },
    },
    [
      _c("v-img", {
        staticClass: "dn-artist-tile__img",
        attrs: { src: _vm.artist.avatarUrl, "aspect-ratio": 1 },
      }),
      _c("p", { staticClass: "dn-artist-tile__name" }, [
        _vm._v(" " + _vm._s(_vm.artist.name) + " "),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }