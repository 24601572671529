var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "dn-tabs" },
    _vm._l(_vm.items, function (item, i) {
      return _c(
        "div",
        {
          key: i,
          ref: "tab",
          refInFor: true,
          class:
            "dn-tabs__tab " + (i === _vm.active ? "dn-tabs__tab--active" : ""),
          on: {
            click: function ($event) {
              $event.preventDefault()
              $event.stopPropagation()
              return _vm.$emit("tab", i)
            },
          },
        },
        [_vm._v(" " + _vm._s(item) + " ")]
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }